import React, { Component } from "react";


import Layout from "../components/layout"
import Banner from "../components/others/banner"

import { StaticQuery, graphql } from "gatsby"

const objectGraphql = graphql`
{
    strapiContacto(locale: {eq: "es-PE"}) {
    
      Banner {
        fondo {
          url
        }
        url {
          titulo
          id
          enlace
        }
        titulo
        id
      }
    }
  }
  
`


class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOffice: 1
        };

        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(item) {
        console.log(item);
        this.setState({
            selectOffice: item
        });
    }
    render() {


        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <React.Fragment>
                        {console.log(data)}
                        <Layout props={this.props.props}>
                            <section className="padding-section-int">
                                <div className="container">


                                    <div id="node-1862" className="node node-page  clearfix" about="/es/contacto/gracias" typeof="foaf:Document">

                                        <span property="dc:title" content="Contacto" className="rdf-meta element-hidden"></span>
                                        <div className="content clearfix">

                                            
                                            {data.strapiContacto ?   <p>Centinella</p>:<p>Error</p>}
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </Layout>
                    </ React.Fragment>
                )}
            />
        )
    }
}

export default Contact
