import * as React from "react"

import Health from "../route/health"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Health props={props} />
      </React.Fragment>
  )
}

export default IndexPage
